<template>
  <div class="w1092">
      <h3 class="pagename">보유머니 충전</h3>

      <div class="moneyread">
        <h3><img src="@/assets/img/mypage_point_waring.png" />확인/필독사항</h3>
        <ul>
          <li><em>✓</em> 입금 시 반드시 회원님의 성함으로 입금 바랍니다.</li>
          <li><em>✓</em> 입금이 지연될 수 있으니 은행 점검 시간을 확인하신 후 점검 시간을 피해 신청해 주시기 바랍니다.</li>
          <li><em>✓</em> 입금이 지연될 수 있으니 은행 점검 시간을 확인하신 후 점검 시간을 피해 신청해 주시기 바랍니다.</li>
          <li><em>✓</em> 입금계좌는 수시로 변경되오니 반드시 계좌번호 문의 신청을 통해 계좌번호를 확인 후 입금하여 주시기 바랍니다.</li>
          <li><em>✓</em> 자세한 문의사항은 고객센터를 이용해 주시기 바랍니다.</li>
        </ul>
      </div>

      <div class="moneyinfoappli">
        <div class="moneyinfo">
          <h3 class="applih">입금 계좌 정보 확인</h3>
          <p class="applip">입금 계좌번호는 쪽지로 발송되며, 발송한 계좌번호로 선입금 바랍니다.</p>
          <a class="applibtn">계좌번호 신청</a>

          <ul class="applistep">
            <li><img src="@/assets/img/step1.png">위 <strong>계좌번호 신청</strong>버튼을 누르시면 계좌번호가 쪽지로 발송됩니다.</li>
            <li><img src="@/assets/img/step2.png">안내받은 계좌번호로 선입금 해주세요.</li>
            <li><img src="@/assets/img/step3.png"><strong>충전금액 입력</strong>란에 실제 입금하신 금액과 동일한 금액을 입력하고 <strong>신청하기</strong> 버튼을 눌러주세요.</li>
          </ul>
        </div>

        <div class="appliinputwrap">
          <ul class="appliinput">
            <li>현재 보유머니</li>
            <li><span class="or b">{{thousand(userData.cashAmt)}}</span> 원</li>
          </ul>
          <ul class="appliinput">
            <li>충전금액 입력</li>
            <li><input type="number" class="numb" placeholder="직접 입력시 숫자만 입력해 주세요." v-model="setCurrentChargeMoney"></li>
          </ul>
          <ul class="moneybtnwrap ml210">
            <li class="one"><a @click="setMoney(10000)">1만원</a></li>
            <li class="one"><a @click="setMoney(30000)">3만원</a></li>
            <li class="one"><a @click="setMoney(50000)">5만원</a></li>
            <li class="two"><a @click="setMoney(100000)">10만원</a></li>
            <li class="thr"><a @click="setMoney(300000)">30만원</a></li>
            <li class="four"><a @click="setMoney(500000)">50만원</a></li>
            <li class="fiv"><a @click="setMoney(1000000)">100만원</a></li>
            <li class="six"><a @click="setMoney(0)">정정</a></li>
          </ul>
          <ul class="appliinput">
            <li>보너스 금액</li>
            <li><span class="blc b">{{thousand(bonus)}}</span> 원</li>
          </ul>
          <ul class="appliinput">
            <li>보너스 혜택</li>
            <li class="moneybtnwrap">
              <a class="sev"><input type="checkbox" :checked="isApplyBonus === true" @click="isApplyBonus = true">보너스 받기</a>
              <a class="eig"><input type="checkbox" :checked="isApplyBonus === false" @click="isApplyBonus = false">보너스 받지않기</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="boardbtn"><a class="blsbtn" @click="onSubmit('pc')">신청하기</a></div>
      <div class="board">
        <ul class="boardw">
          <li class="">신청일시</li>
          <li class="">충전금액</li>
          <li class="">보너스금액</li>
          <li class="">처리일시</li>
          <li class="">처리상태</li>
        </ul>
        <template v-if="cashList.length > 0">
          <ul class="boardw" v-for="item in cashList" v-bind:key="item.cashIdx">
            <li class="">{{dateFormat(item.regDate)}}</li>
            <li class="">{{thousand(item.cashAmt)}}</li>
            <li class="">{{thousand(item.preCashAmt)}}</li>
            <li class="">{{dateFormat(item.updDate)}}</li>
            <li class=""><span class="bl">완료</span></li>
          </ul>
        </template>
        <template v-else>
          <ul class="boardw">
            <li>내역이 없습니다.</li>
          </ul>
        </template>
<!--          <ul class="boardw">-->
<!--            <li class="">2021.06.29 15:36</li>-->
<!--            <li class="">12,000</li>-->
<!--            <li class="">+1.000</li>-->
<!--            <li class="">2021.06.29 15:36</li>-->
<!--            <li class=""><span class="bl">완료</span></li>-->
<!--          </ul>-->
<!--          <ul class="boardw">-->
<!--            <li class="">2021.06.29 15:36</li>-->
<!--            <li class="">12,000</li>-->
<!--            <li class="">+1.000</li>-->
<!--            <li class="">2021.06.29 15:36</li>-->
<!--            <li class=""><span class="">취소</span></li>-->
<!--          </ul>-->
<!--          <ul class="boardw">-->
<!--            <li class="">2021.06.29 15:36</li>-->
<!--            <li class="">12,000</li>-->
<!--            <li class="">+1.000</li>-->
<!--            <li class="">2021.06.29 15:36</li>-->
<!--            <li class=""><span class="bl">완료</span></li>-->
<!--          </ul>-->
      </div>
      <div class="boardpage mt20 mb20">
        <pagination :pageNum="pageInfo.page"
                    :pageSize="pageInfo.count_per_list"
                    :totalCount="pageInfo.tatal_list_count"
                    @goToPage="onCashListSearch" />
      </div>
      <div class="board bankwrap">
        <ul class="boardw">
          <li><img src="@/assets/img/kb.png"><span>23:30~00:05</span></li>
          <li><img src="@/assets/img/kebbank.png"><span>23:30~00:05</span></li>
          <li><img src="@/assets/img/suhyup.png"><span>00:00~01:00</span></li>
        </ul>
        <ul class="boardw">
          <li><img src="@/assets/img/woori.png"><span>00:00~00:10<br>(금 00:00~00:04)</span></li>
          <li><img src="@/assets/img/dgb.png"><span>00:00~00:10<br>(금 00:00~08:00)</span></li>
          <li><img src="@/assets/img/ibk.png"><span>00:00~00:10</span></li>
        </ul>
        <ul class="boardw">
          <li><img src="@/assets/img/shbank.png"><span>23:00~24:00<br>(타행이체불가)</span></li>
          <li><img src="@/assets/img/bs.png"><span>00:00~01:30</span></li>
          <li><img src="@/assets/img/sinhyup.png"><span>23:50~00:05</span></li>
        </ul>
        <ul class="boardw">
          <li><img src="@/assets/img/keb.png"><span>00:00~01:00<br>(일 00:00~08:00)</span></li>
          <li><img src="@/assets/img/gjbank.png"><span>23:50~00:10</span></li>
          <li><img src="@/assets/img/everRich.png"><span>23:50~00:00<br>(04:00~05:00)</span></li>
        </ul>
        <ul class="boardw">
          <li><img src="@/assets/img/sc.png"><span>00:00~00:30</span></li>
          <li><img src="@/assets/img/bnk.png"><span>00:00~00:20</span></li>
          <li><img src="@/assets/img/mg.png"><span>23:50~00:30</span></li>
        </ul>
        <ul class="boardw">
          <li><img src="@/assets/img/citi.png"><span>23:40~00:05</span></li>
          <li><img src="@/assets/img/nh.png"><span>00:00~00:30</span></li>
        </ul>
      </div>

    </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import ChargeMobile from '@/views_mobile/member/charge/charge'
export default {
  name: 'Charge',
  mixins: [
    ChargeMobile
  ],
  components: {
    Pagination
  },
  created () {
    this.onCashListSearch()
  }
}
</script>
<style scoped>
.moneyread {padding: 20px;border-radius: 5px;box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);border: solid 1px #cacaca;background-color: #fafafa;}
.moneyread h3 {color: #2f3545;margin-bottom: 10px;font-size: 20px;}
.moneyread h3 img {height: 25px;vertical-align: middle;margin-right: 5px;}
.moneyread ul li {color: #000;letter-spacing: -1px;line-height: 1.5em;margin-top: 5px;}
.moneyread ul li em {font-weight: bold;}

.moneyinfoappli {box-shadow: 1px 0px 5px 1px #f0f0f0;border: 1px solid #2f3545; border-radius: 12px;padding: 10px;margin-top: 20px;}
.moneyinfo {border-radius: 12px;border: 1px dotted #9a7943;background: #fff;padding: 30px 50px;line-height: 1.5em;}
.applih {color: #60452a;font-size: 20px;font-weight: normal;}
.applip {color: #000;border-bottom: 1px solid #9a7943;padding: 10px 0 20px;margin-bottom: 20px;}
.applibtn {border: 1px solid #bf4326;border-radius: 6px;background: linear-gradient(#fe7643, #ea461f);color: #fff;font-weight: normal;padding: 10px 50px;display: inline-block;}

.applistep {color: #000;letter-spacing: -1px;font-weight: bold;margin-top: 20px}
.applistep li {counter-increment: li;margin-top: 5px;}
.applistep li img {vertical-align: middle;margin-right: 10px;}
.applistep strong {color: #393e41;}
.appliinputwrap {padding: 20px 40px 30px;}
.appliinput {display: flex;gap: 10px;margin-top: 30px;font-weight: normal;}
.appliinput li:first-child {width: 200px;}
.appliinput li {width: calc(100% - 200px);border-bottom: 1px solid #bebebe;padding: 10px 0;}
.numb {padding: 0;border: 0;width: 100%; color: #000;font-size: 16px;}
.moneybtnwrap {display: flex;gap: 5px;}
.moneybtnwrap a {display: inline-block;color: #fff;width: 80px;border-radius: 6px;text-align: center;padding: 10px 0;font-weight: bold;margin-top: 10px;}
.moneybtnwrap .one a {background: #003f73;border: 1px solid #004b8a;}
.moneybtnwrap .two a {background: #0f5392;border: 1px solid #2068b2;}
.moneybtnwrap .thr a {background: #2d71bf;border: 1px solid #286fce;}
.moneybtnwrap .four a {background: #4781d2;border: 1px solid #286fce;}
.moneybtnwrap .fiv a {background: #3a95e0;border: 1px solid #177cce;}
.moneybtnwrap .six a {background: #464646;border: 1px solid #000;}
.moneybtnwrap .sev {width: 180px;background: #474747;border: 1px solid #292929;margin: 0;}
.moneybtnwrap .eig {width: 180px;background: #e2324f;border: 1px solid #7e3636;margin: 0;}

.bankwrap {padding: 20px 0;background: #f9f9f9;}
.bankwrap ul:nth-child(even) {background: #f1f1f1;}
.bankwrap ul li {display: flex;justify-content: right;gap:15px;height: 50px;border-left: 1px solid #ddd;}
.bankwrap ul li:first-child {border-left: 0;}
.bankwrap ul span {display: inline-block;width: 50%;text-align: left;color: #7b7b7b;font-weight: normal;}
.bankwrap ul li img {height: 18px;}
</style>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
